import React from 'react';
import { Route, Routes } from 'react-router';
import PromptEnhancerPage from '../promptEnhancer/PromptEnhancer';
import PromptStoreTabs from './PromptStoreTabs';

const PSRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<PromptStoreHome />} /> */}
      <Route path="/*" element={<PromptStoreTabs />} />
      <Route path="/prompt-enhancer" element={<PromptEnhancerPage />} />
    </Routes>
  );
};

export default PSRoutes;
