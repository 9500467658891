import React from 'react';
import {
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
} from '@material-ui/core';
import LinkIcon from '../../../icons/LinkIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)', // for pages without content header
      marginBottom: '10px',
      '& nav': {
        display: 'flex',
      },
    },
    gridItemCardResponsive: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginBottom: '10px',
        width: '100%',
      },
    },
    editBtnStyle: {
      marginRight: '10px',
      marginTop: '10px',
    },
    editBtnResponsive: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    gridItemCardContent: {
      flex: 1,
    },
    spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    iconButton: {
      width: '96px',
      height: '36px',
      color: theme.palette.text.primary,
      borderRadius: '3px',
      fontSize: '16px',
      border: `1px solid ${theme.palette.text.primary}`,
      '& svg': {
        marginRight: '5px',
      },
    },
    rightAlignItem: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    totalDownloads: {
      fontWeight: 'bold'
    }
  }),
);

export function UIUXCard() {
  const classes = useStyles();
  const { entity } = useEntity();
  const onClickLink = () => {
    window.open(entity?.metadata?.links[0].url, '_blank');
  }
  return (
    <>
      <Card className={classes.gridItemCard}>
      <CardContent className={classes.gridItemCardContent}>
        <Grid className={classes.spaceBetween}>
        <Grid xs={12} md={6}>
          <div>Latest Version: {entity?.metadata?.latestversion || '0.0.0'}</div>
          <div className={classes.totalDownloads}>Total downloads: {entity?.metadata?.downloadcount || '0.0'}</div>
        </Grid>
        <Grid xs={12} md={6} className={classes.rightAlignItem}>
          <IconButton onClick={onClickLink} className={classes.iconButton}>
          <LinkIcon />
          Link
          </IconButton>
        </Grid>
        </Grid>
      </CardContent>
      </Card>
    </>
  );
}
