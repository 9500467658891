import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DesignIcon = (_: any ,filled?: boolean) => {
  if (filled) {
    return <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="design_services">
        <g id="Vector">
          <path d="M16.2385 11.5089L17.8085 9.93889L14.0585 6.18889L12.4885 7.75889L8.34854 3.62889C7.56854 2.84889 6.29854 2.84889 5.51854 3.62889L3.61854 5.52889C2.83854 6.30889 2.83854 7.57889 3.61854 8.35889L7.74854 12.4889L3.14854 17.0989C3.04854 17.1989 2.99854 17.3189 2.99854 17.4589V20.4989C2.99854 20.7789 3.21854 20.9989 3.49854 20.9989H6.53854C6.66854 20.9989 6.79854 20.9489 6.88854 20.8489L11.5085 16.2289L15.6385 20.3589C16.9585 21.6789 18.3985 20.4289 18.4685 20.3589L20.3685 18.4589C21.1485 17.6789 21.1485 16.4089 20.3685 15.6289L16.2385 11.5089ZM9.17854 11.0689L5.03854 6.93889L6.92853 5.03889L8.19853 6.30889L7.72854 6.79889C7.33854 7.18889 7.33854 7.81889 7.72854 8.20889C8.11854 8.59889 8.74854 8.59889 9.13854 8.20889L9.61854 7.72889L11.0685 9.17889L9.17854 11.0689ZM17.0585 18.9589L12.9285 14.8289L14.8285 12.9289L16.2785 14.3789L15.7985 14.8589C15.4085 15.2489 15.4085 15.8789 15.7985 16.2689C16.1885 16.6589 16.8185 16.6589 17.2085 16.2689L17.6885 15.7889L18.9585 17.0589L17.0585 18.9589Z" />
          <path d="M20.7085 7.03889C21.0985 6.64889 21.0985 6.01889 20.7085 5.62889L18.3685 3.28889C17.8985 2.81889 17.2485 2.99889 16.9585 3.28889L15.1285 5.11889L18.8785 8.86889L20.7085 7.03889Z" />
        </g>
      </g>
    </SvgIcon>
  }

  return <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="design_services">
      <path id="Vector" d="M17.3656 6.16771C17.6906 5.84271 17.6906 5.31771 17.3656 4.99271L15.0073 2.63437C14.6823 2.30938 14.1573 2.30938 13.8323 2.63437L10.0906 6.37604L6.84896 3.13438C6.19896 2.48438 5.14062 2.48438 4.49063 3.13438L2.90729 4.71771C2.25729 5.36771 2.25729 6.42604 2.90729 7.07604L6.14896 10.3177L2.39062 14.076V17.6094H5.92396L9.69063 13.8427L12.9323 17.0844C13.724 17.876 14.7906 17.5844 15.2906 17.0844L16.874 15.501C17.524 14.851 17.524 13.7927 16.874 13.1427L13.6323 9.90104L17.3656 6.16771ZM4.09063 5.89271L5.66563 4.30937L6.72396 5.36771L5.74063 6.35937L6.91563 7.53437L7.90729 6.54271L8.90729 7.54271L7.32396 9.12604L4.09063 5.89271ZM13.449 12.0927L12.4573 13.0844L13.6323 14.2594L14.624 13.2677L15.6823 14.326L14.099 15.9094L10.8573 12.6677L12.4406 11.0844L13.449 12.0927ZM5.23229 15.9427H4.05729V14.7677L12.0656 6.75937L13.2406 7.93437L5.23229 15.9427ZM13.2406 5.57604L14.4156 4.40104L15.5906 5.57604L14.4156 6.75104L13.2406 5.57604Z" />
    </g>
  </SvgIcon>
};

export default DesignIcon;