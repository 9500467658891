import React, { useEffect, useState } from 'react';
import SideNav from './EmeraldSideBar/SideNav';
import { EmeraldAppLayout } from '@emerald-react/side-navigation';
import SideNavPage from './EmeraldSideBar/SideNavPage';
import AppSidebar from './BackstageSideBar/Sidebar';
import { SidebarPage } from '@backstage/core-components';
import NavigateOut from '../NavigationOutOfDevx/NavigateOut';
import { useSplitTreatments } from '@splitsoftware/splitio-react';

interface DevxSidebarProps {
  isAdminFlag: boolean | null;
  userName: string;
  profile: any;
  handleYes: () => void;
  handleClose: () => void;
  openStorybook: boolean;
  children: React.ReactNode;
}

const DevXSidebar = ({
  isAdminFlag,
  userName,
  profile,
  handleYes,
  handleClose,
  openStorybook,
  children,
}: DevxSidebarProps) => {
  const NaviagteProps = {
    handleYes: handleYes,
    handleClose: handleClose,
    open: openStorybook,
  };

  const featureFlag = 'devx-ui-feature-enable';
  const { treatments } = useSplitTreatments({
    names: [featureFlag],
  });

  enum sidebarTypes {
    emerald = 'emerald',
    backstage = 'backstage',
  }

  const [sidebarType, setSidebarType] = useState<null | sidebarTypes>(null);

  useEffect(() => {
    if (treatments[featureFlag]?.config) {
      const configObj = JSON.parse(treatments[featureFlag].config);
      setSidebarType(configObj.sidebarType || sidebarTypes.backstage);
    }
  }, [treatments]);

  return (
    <>
      {(() => {
        switch (sidebarType) {
          case sidebarTypes.emerald:
            return (
              <SideNavPage>
                <EmeraldAppLayout
                  sideNavigation={
                    <SideNav
                      isAdminFlag={isAdminFlag}
                      userName={userName}
                      profile={profile}
                    />
                  }
                >
                  {children}
                </EmeraldAppLayout>
              </SideNavPage>
            );
          case sidebarTypes.backstage:
            return (
              <SidebarPage>
                <AppSidebar
                  handleYes={handleYes}
                  isAdminFlag={isAdminFlag}
                  userName={userName}
                  profile={profile}
                />
                {children}
                {openStorybook && <NavigateOut {...NaviagteProps} />}
              </SidebarPage>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default DevXSidebar;
