/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';

export const useProvisionedServiceDetails = (
  apmNumber: string | undefined,
  service: string,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [provisionedDetails, setProvisionedDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMyAPI = async () => {
    setLoading(true);
    if (toolsprovisioningApi && apmNumber) {
      try {
        const response: any =
          await toolsprovisioningApi.getProvisionedProjectDetails(
            apmNumber,
            service,
          );
        if (response?.status === 200) {
          setLoading(false);
          setProvisionedDetails(response?.data);
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };
  useEffect(() => {
    fetchMyAPI();
  }, []);

  const refreshProvisioned = () => {
    setLoading(true);
    fetchMyAPI();
  };

  return [provisionedDetails, loading, refreshProvisioned] as const;
};
