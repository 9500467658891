import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { IconComponent } from '@backstage/core-plugin-api';
import {
  SearchModal,
  SearchModalProps,
  SearchModalProvider,
  useSearchModal,
} from '@backstage/plugin-search';

/**
 * Props for {@link SidebarSearchModal}.
 *
 * @public
 */
export type SidebarSearchModalProps = Pick<
  SearchModalProps,
  'children' | 'resultItemComponents'
> & {
  icon?: IconComponent;
};

const SidebarSearchModalContent = (props: SidebarSearchModalProps) => {
  const { state, toggleModal } = useSearchModal();

  return (
    <>
      <div
        className="emerald-nav-item"
        role="menuitem"
        aria-label="menu item"
        onClick={toggleModal}
      >
        <div className="emerald-nav-wrapper">
          <div className="emerald-nav-link">
            <span className="emerald-nav-icon-pre">
              <i className="emerald-icon material-icons" aria-hidden="false">
                search
              </i>
            </span>
            <span className="emerald-nav-label" title="Home">
              Search
            </span>
          </div>
        </div>
      </div>

      <SearchModal
        {...state}
        toggleModal={toggleModal}
        resultItemComponents={props.resultItemComponents}
        children={props.children}
      />
    </>
  );
};

export const SidebarSearchModal = (props: SidebarSearchModalProps) => {
  return (
    <SearchModalProvider>
      <SidebarSearchModalContent {...props} />
    </SearchModalProvider>
  );
};
