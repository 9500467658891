import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MsgPopper from './MsgPopper';
import { GuidedTourContext } from './providers/GuidedTourProvider';
import { TourObj } from './interfaces';
import { getAnchorElement } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trasparentOverlay: {
      position: 'fixed',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'transparent',
      zIndex: 1,
      cursor: 'pointer',
    },
    hole: {
      position: 'absolute',
      boxShadow: '0 0 0 9999px #00000060',
      transitionProperty: 'left, top, height, width',
      transitionDuration: '500ms',
      zIndex: 12,
    },
    '@keyframes blinker': {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
    blink: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      background: 'blue',
      animation: 'blinker 1s infinite',
      position: 'absolute',
      top: 'calc(50% - 12px)',
      right: '10px',
    },
  }),
);
const TourConfigRunner = ({
  tourConfig,
  currentStep,
  onStepChange,
}: {
  tourConfig: TourObj[];
  currentStep: number;
  onStepChange: Function;
}) => {
  const classes = useStyles();
  const myContext = useContext(GuidedTourContext);

  useEffect(() => {}, [myContext.startTour]);

  const [highlightedElement, setHiglightedElement] = useState<any>();
  const [boundingRect, setBoundingRect] = useState<any>();
  const [msgPopperOpen, setMsgPopperOpen] = useState(false);
  const spacing = 10;
  /* Local currentIndex varaible */
  const [currentIndex, setCurrentIndex] = useState(currentStep);

  const selectorsArr = useRef<any>();
  const [seletorIndex, setSelectorIndex] = useState(0);

  const clickOrHover = (element: HTMLElement) => {
    // For Clickbased Menu
    element?.dispatchEvent(new Event('click', { bubbles: true }));

    // For Hover based Menu
    element?.dispatchEvent(new MouseEvent('mouseover', { bubbles: true }));
  };

  const removeClickOrHover = () => {
    if (selectorsArr.current.length > 1) {
      const element = getAnchorElement(selectorsArr.current[0]);
      // For Clickbased Menu - close by clicking again
      element?.dispatchEvent(new Event('click', { bubbles: true }));

      // For Hover based Menu - do mouse out on the first element
      element?.dispatchEvent(new MouseEvent('mouseout', { bubbles: true }));
    }
  };

  /* Resets the ui - remove the clicks or hovers, closes any poppers */
  const resetUi = () => {
    setSelectorIndex(prevState => prevState * 0); // Reset selector index to 0
    removeClickOrHover();
    setMsgPopperOpen(false);
  };

  useEffect(() => {
    if (tourConfig === null) return;

    selectorsArr.current = tourConfig[currentIndex].selector.split('\n');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  useEffect(() => {
    // if (tourConfig === null) return;

    // selectorsArr.current = tourConfig[currentIndex].selector.split('\n');

    // selectorsArr.current.forEach((selector: string, index: number) => {
    // setTimeout(() => {
    const selector = selectorsArr.current[seletorIndex];
    const higlightElement: any = getAnchorElement(selector);

    if (higlightElement) {
      higlightElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      setTimeout(() => {
        setBoundingRect(higlightElement?.getBoundingClientRect());
        if (seletorIndex === selectorsArr.current.length - 1) {
          setHiglightedElement(higlightElement);
          setMsgPopperOpen(true);
        } else {
          setTimeout(() => {
            clickOrHover(higlightElement);
            setSelectorIndex(prevState => prevState + 1);
          }, 200);
        }
      }, 400);
    } else {
      setHiglightedElement(null);
      setBoundingRect(null);
      setMsgPopperOpen(true);
    }
    // onStepChange(currentIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, seletorIndex]);

  /* Handler for close button */
  const handleCloseClick = () => {
    resetUi();
    setCurrentIndex(prevState => prevState * 0);
    myContext.setStartTour(false);
    setBoundingRect(null);
  };

  const handleExplore = () => {
    //   const element = getAnchorElement();
    onStepChange('explore', currentIndex);
    highlightedElement.click();
    setMsgPopperOpen(false);
  };

  /* Handler for back click */
  const handleBackClick = () => {
    resetUi();
    if (currentIndex === 0) {
      onStepChange('back', 0);
      //  handleCloseClick();
    } else {
      onStepChange('back', currentIndex);
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  /* Handler for next click */
  const handleNextClick = () => {
    resetUi();

    if (currentIndex === tourConfig.length - 1) {
      onStepChange('next', currentIndex);
      //     handleCloseClick();
    } else {
      setCurrentIndex(prevState => prevState + 1);
      onStepChange('next', currentIndex);
    }
  };

  const getClipPath = () => {
    const top = `${boundingRect?.top - spacing}px`;
    const left = `${boundingRect?.left - spacing}px`;
    const right = `${boundingRect?.right + spacing}px`;
    const bottom = `${boundingRect?.bottom + spacing}px`;

    return `polygon( evenodd, 0 0, 100% 0, 100% 100%, 0% 100%,  0 0, ${left} ${top},${right} ${top}, ${right} ${bottom},${left} ${bottom}, ${left} ${top})`;
  };

  return (
    <>
      {tourConfig ? (
        <>
          <div
            className={classes.trasparentOverlay}
            style={{ clipPath: getClipPath() }}
          />
          <div
            className={classes.hole}
            style={{
              top: boundingRect?.top - spacing,
              left: boundingRect?.left - spacing,
              width: boundingRect?.width + spacing * 2 || 0,
              height: boundingRect?.height + spacing * 2 || 0,
              pointerEvents: tourConfig[currentIndex]?.enableClick
                ? 'none'
                : 'inherit',
            }}
          >
            {tourConfig[currentIndex]?.enableClick ? (
              <div className={classes.blink} />
            ) : null}
          </div>

          <MsgPopper
            open={msgPopperOpen}
            key={currentIndex}
            data={tourConfig[currentIndex]}
            isFirstSlide={currentIndex === 0}
            isLastSlide={currentIndex === tourConfig.length - 1}
            onCloseClick={handleCloseClick}
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}
            anchorEl={highlightedElement}
          />
        </>
      ) : null}
    </>
  );
};

export default TourConfigRunner;
