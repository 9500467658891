import React from 'react';
import { Route, Routes } from 'react-router';
import ToolsManagementPage from '../ToolsManagementPage';
import { PTRoutes } from './PerformanceTest/PTRoutes';
import ProjectListPage from './PlannerQEBot/views/ProjectListPage';

export const ServiceRoutes = ({ isToolAdmin }: { isToolAdmin: boolean }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ToolsManagementPage
            type="service"
            key="service"
            isToolAdmin={isToolAdmin}
          />
        }
      />
      <Route
        path="/performance-test/*"
        element={<PTRoutes isToolAdmin={isToolAdmin} />}
      />
      <Route
        path="/jira-planner-qe-bot"
        element={<ProjectListPage isToolAdmin={isToolAdmin} service="jira" />}
      />
      <Route
        path="/ado-planner-qe-bot"
        element={<ProjectListPage isToolAdmin={isToolAdmin} service="ado" />}
      />
    </Routes>
  );
};
