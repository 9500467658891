import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { commonStyles } from '../../../../../../styles/common';
import CloseIcon from '@material-ui/icons/Close';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...commonStyles(theme),
    popupTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    disableColor: {
      backgroundColor: theme.palette.errorBackground,
    },
    enableColor: {
      backgroundColor: theme.palette.successBackground,
    },
    description: {
      fontSize: `${theme.spacing(3)}px`,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'self-start',
      gap: `${theme.spacing(1.25)}px`,
    },
  }),
);

interface ConfirmationPopupProps {
  open: boolean;
  popupContent: {
    title: string;
    description: string;
    subDescription: string;
    enabled: boolean;
  };
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationPopup = ({
  open,
  popupContent,
  onClose,
  onConfirm,
}: ConfirmationPopupProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: `${classes.minWidth600} ${classes.secondaryText}` }}
    >
      <div className={classes.popupTitle}>
        <DialogTitle classes={{ root: classes.padding2 }}>
          {popupContent.title}
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers classes={{ dividers: classes.bottomBorderNone }}>
        <div
          className={`${
            popupContent.enabled ? classes.disableColor : classes.enableColor
          } ${classes.padding2Point5}`}
        >
          <div
            className={`${classes.description} ${classes.marginBottomSmall}`}
          >
            <span className={classes.marginTopPoint5}>
              {popupContent.enabled ? <BlockIcon /> : <CheckCircleIcon />}
            </span>
            <span>{popupContent.description}</span>
          </div>
          <div className={classes.tertiaryText}>
            {popupContent.subDescription}
          </div>
        </div>
      </DialogContent>
      <DialogActions
        className={`${classes.marginBottomSmall} ${classes.marginRight2}`}
      >
        <Button color="primary" onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
