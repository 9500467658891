/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';

export const useGetProvisionedTools = (
  apmNumber: string | undefined,
  tool: string,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [provisionedToolsAPM, setProvisionedTools] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMyAPI = async () => {
    setIsLoading(true);
    if (toolsprovisioningApi && apmNumber) {
      try {
        const response: any =
          await toolsprovisioningApi.getProvisionedTools(
            apmNumber,
            tool,
          );
        if (response?.status === 200) {
          setIsLoading(false);
          setProvisionedTools(response?.data);
        }
      } catch (error) {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };
  useEffect(() => {
    fetchMyAPI();
  }, []);

  const refreshProvisionedTools = () => {
    setIsLoading(true);
    fetchMyAPI();
  };

  return [provisionedToolsAPM, isLoading, refreshProvisionedTools] as const;
};
