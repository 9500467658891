import { useState, useEffect } from 'react';

const useMutationObserver = (initialState: boolean) => {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const target = mutation.target as HTMLElement;
          if (target.classList.contains('collapsed')) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        }
      });
    });

    // Assuming you have a target element to observe
    const targetElement = document.querySelector('.emerald-side-navigation');
    if (targetElement) {
      observer.observe(targetElement, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return isOpen;
};

export default useMutationObserver;
