import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  Theme,
  Zoom,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* Popper Classes */
    popper: {
      zIndex: 12,
      transitionProperty: 'opacity, left, top, height',
      transitionDuration: '1s, 1s',
    },
    centerFullScreen: {
      position: 'fixed',
      top: '50% !important',
      left: '50% !important',
      transform: 'translate(-50%, -50%)',
    },
    container: {
      width: '500px',
      transitionProperty: 'left, top, height, width',
      transitionDuration: '1s',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export interface MsgPopperData {
  title: string;
  description: string;
  placement?: PopperPlacementType;
}

const MsgPopper = ({
  open = false,
  data,
  anchorEl,
  onCloseClick,
  // enableClick,
  // onExploreClick,
  onBackClick,
  onNextClick,
  isFirstSlide,
  isLastSlide,
}: {
  open: boolean;
  data: MsgPopperData;
  anchorEl: HTMLElement;
  onCloseClick: Function;
  onBackClick: Function;
  onNextClick: Function;
  isFirstSlide: boolean;
  isLastSlide: boolean;
}) => {
  const classes = useStyles();

  /*   const [isLoaded, setIsLoaded] = useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 10);

    return () => clearTimeout(timer);
  }, [anchorEl]); */

  /* get popper classes */
  const getPopperClasses = () => {
    return anchorEl
      ? classes.popper
      : `${classes.popper} ${classes.centerFullScreen}`;
  };

  return (
    <>
      {open ? (
        <Popper
          open={open}
          anchorEl={anchorEl}
          className={getPopperClasses()}
          placement={data.placement}
          transition
        >
          <Zoom
            in
            style={{ transitionDelay: isFirstSlide ? '200ms' : '500ms' }}
          >
            <Paper className={classes.container}>
              <DialogTitle>
                {data.title}
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={() => onCloseClick()}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {data.description}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => onBackClick()}
                  color="primary"
                  variant="outlined"
                >
                  {isFirstSlide ? 'Skip' : 'Back'}
                </Button>
                <Button
                  onClick={() => onNextClick()}
                  color="primary"
                  variant="contained"
                >
                  {isLastSlide ? 'Finish' : 'Next'}
                </Button>
              </DialogActions>
            </Paper>
          </Zoom>
        </Popper>
      ) : null}
    </>
  );
};

export default MsgPopper;
