import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton, makeStyles, Paper, Theme } from '@material-ui/core';
import SmartToyIcon from '@material-ui/icons/SmartToy';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import OpenAI from '../openai/openai';
import { ChatBotContext } from '../../providers/ChatBotProvider';

const useStyles = makeStyles((theme: Theme) => ({
  openaiChatbot: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    zIndex: 10,
  },
  chatIcon: {
    '& span': {
      color: theme.palette.text.contrast,
      '& svg': {
        width: '40px',
        height: '40px',
      },
    },
  },
  popover: {
    // width: '350px',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    bottom: 0,
    right: 0,
    position: 'absolute',
    opacity: '1',
    transform: 'none',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transformOrigin: '0px 0px',
    // [theme.breakpoints.down('md')]: {
    //   width: '100%',
    //   height: '100%',
    //   bottom: 0,
    //   right: 0
    // },
  },
  paper: {
    width: '90%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  title: {
    padding: '10px 8px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.contrast,
  },
  text: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  chat: {
    padding: theme.spacing(1),
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  chatBox: {
    padding: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  textField: {
    width: '100%',
    '& input': {
      padding: '5px',
    },
  },
  sendIcon: {
    padding: '2px 0px 0px 8px',
  },
  send: {
    color: theme.palette.primary.main,
  },
  sendBox: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'center',
  },
  ai: {
    width: '75%',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  user: {
    position: 'relative',
    maxWidth: '90%',
    marginBottom: theme.spacing(1),
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    padding: '10px 14px 10px 14px',
    color: theme.palette.text.contrast,
  },
  userDiv: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  aiDiv: {
    display: 'flex',
  },
  aiIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: theme.palette.text.contrast,
    },
  },
  iframe: {
    height: 'calc(100% + 61.1px)',
    marginTop: '-61.1px',
    position: 'relative',
  },
}));

const Chatbot = () => {
  const [showChat, setShowChat] = useState<HTMLButtonElement>();
  // const [showCitation, setShowCitation] = useState<boolean>(false);
  // const [focus, setFocus] = useState<boolean>(false);
  // const [question, setQuestion] = useState<any>('');
  // const [chatContent, setChatContent] = useState<any>([]);
  const { enabled } = useContext(ChatBotContext);
  const classes = useStyles();
  const location = useLocation();
  const showIcon = location.pathname.includes('/prompt-store');

  if (enabled) return <></>;

  return (
    <>
      {showChat && (
        <div className={classes.popover}>
          <Paper className={classes.paper}>
            <OpenAI />
          </Paper>
        </div>
      )}
      <div
        className={classes.openaiChatbot}
        style={{
          display: showIcon ? 'none' : 'block',
        }}
      >
        <IconButton
          className={classes.chatIcon}
          onClick={(ev: React.SyntheticEvent<HTMLButtonElement>) => {
            if (showChat) {
              setShowChat(undefined);
            } else {
              setShowChat(ev.currentTarget);
            }
          }}
        >
          {showChat ? <CloseIcon /> : <SmartToyIcon />}
        </IconButton>
      </div>
    </>
  );
};

export default Chatbot;
