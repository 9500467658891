/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router';
import { useAddTool } from '../../../../hooks/useAddTool';
import { useContext } from 'react';
import { AuthContext } from '../../../../../../providers/AuthProvider';
import { TPContext } from '../../../../providers/TPProvider';
import CONSTANTS from '../../../constants';

const { ENABLE_JIRAPLANNERBOT, ENABLE_ADOPLANNERBOT } = CONSTANTS.TOOLS;

export const useAddProject = () => {
  const [addTool] = useAddTool();

  const { projectId: apmNumber } = useParams();
  const { profEmail } = useContext(AuthContext);
  const { apmInfo } = useContext(TPContext);

  const addProject = async (
    jiraProjectId: string,
    isEnabled: boolean,
    service: string,
  ) => {
    const toolToAdd = {
      'apm-number': apmNumber || '',
      tool: service === 'jira' ? ENABLE_JIRAPLANNERBOT : ENABLE_ADOPLANNERBOT,
      'instance-name':
        service === 'jira'
          ? `${ENABLE_JIRAPLANNERBOT} ${apmInfo?.Name}`
          : jiraProjectId,
      'provision-key': apmNumber || '',
      'requestor-principal': profEmail,
      'env-prod': false,
      'is-unprovision': !isEnabled,
      'additional-data': {
        'instance-id': jiraProjectId,
        user_email: profEmail,
        is_enabled: isEnabled,
      },
    };
    return addTool(
      toolToAdd,
      `Project ${jiraProjectId} has been ${isEnabled ? 'enabled' : 'disabled'}`,
    );
  };

  return { addProject } as const;
};
