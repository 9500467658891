/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../apis/toolsProvisioningApi';
import { IAddToolRequest } from '../interfaces';

export const useAddTool = () => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const addTool = async (toolToAdd: IAddToolRequest, toastMsg?: string) => {
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.addToolToProject(toolToAdd);
        if (response?.status === 'SUCCESS') {
          notificationApi.sendNotification({
            message:
              toastMsg ||
              'Your request has been submitted successfully. You will receive an email notification with the next steps once the provisioning is completed', // response?.reason || 'Success',
            disapperAfterMs: 3000,
            severity: 'success',
          });
          return response.reason;
        }
      } catch (error) {
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
    return null;
  };

  return [addTool];
};
