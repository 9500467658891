import {
  genPageTheme,
  shapes,
  createUnifiedTheme,
  themes,
} from '@backstage/theme';
import { yellow } from '@material-ui/core/colors';

const darkCbreTheme = createUnifiedTheme({
  palette: {
    ...themes.dark.getTheme('v5').palette,
    background: {
      default: '#333333',
      paper: '#424242',
      tertiary: '#262626',
      secondary: '#292929',
    },
    primary: {
      main: '#17E88F',
      dark: '#17E88F',
    },
    secondary: {
      main: '#96B3B6',
      active: '#17E88F',
    },
    error: {
      main: '#FF543E',
    },
    warning: {
      main: '#FF9742',
    },
    info: {
      main: '#8585E0',
    },
    success: {
      main: '#50AF78',
    },
    toolTip: {
      background: '#fff',
      textColor: '#000',
    },
    alert: {
      background: '#4E4657',
      border: '#A388BF',
      link: '#17E88F',
      color: '#fff',
    },
    banner: {
      info: '#2E77D0',
      error: '#E22134',
      text: '#FFFFFF',
      link: '#000000',
      closeButtonColor: '#FFFFFF',
      warning: '#FF9800',
    },
    status: {
      ok: '#71CF88',
      warning: '#FFB84D',
      error: '#F84C55',
      running: '#3488E3',
      pending: '#FEF071',
      aborted: '#9E9E9E',
    },
    bursts: {
      fontColor: '#FEFEFE',
      slackChannelText: '#ddd',
      backgroundColor: {
        default: '#7C3699',
      },
      gradient: {
        linear: 'linear-gradient(-137deg, #4BB8A5 0%, #187656 100%)',
      },
    },
    chat: {
      primary: {
        background: '#80BBAD',
        text: '#FFF',
        hyperText: '#262698',
        button: 'rgba(255, 255, 255, 0.15)',
      },
      secondary: {
        background: '#DBD99A',
        main: '#FFF',
      },
      prompt: {
        background: '#333333',
        promptActive: '#5c5c5c',
      },
    },

    border: 'rgba(255, 255, 255, 0.12)',
    textContrast: '#FFFFFF',
    textVerySubtle: '#727272',
    textSubtle: '#CCCCCC',
    highlight: '#FFFBCC',
    successBackground: '#28573C',
    errorBackground: '#A03530',
    warningBackground: '#B85F00',
    infoBackground: '#262698',
    successText: '#50AF78',
    errorText: '#FF543E',
    infoText: '#8585E0',
    warningText: '#FF9742',
    linkHover: '#82BAFD',
    link: '#9CC9FF',
    gold: yellow.A700,
    navigation: {
      background: '#424242',
      indicator: '#9BF0E1',
      color: '#b5b5b5',
      selectedColor: '#FFF',
      navItem: {
        hoverBackground: '#404040',
      },
      submenu: {
        background: '#404040',
      },
    },
    sideNavigation: {
      primary: '#F7F6ED',
      secondary: '#A1A19C',
      colorActive: '#17E88F',
      colorBackground: '#424242',
      colorBorder: '#767676',
      colorHoverBackground: 'rgba(255, 255, 255, 0.08)',
      colorActiveBackground: 'rgba(255, 255, 255, 0.16)',
      colorIcon: '#A1A19C',
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      tertiary: 'rgba(255,255,255,0.6)',
      contrast: '#1A1A1A',
      muted: 'rgba(226, 226, 226, 0.6)',
      signIn: '#fff',
      buttonText: '#00000',
    },
    customCard: {
      cardHeader: '#FFF',
      tabHighlight: '#17E88F',
      foldBg: '#333333',
    },
    table: {
      headerBackground: '#565656',
    },
    textEditor: {
      color: '#000',
    },
    pinSidebarButton: {
      icon: '#404040',
      background: '#BDBDBD',
    },
    tabbar: {
      indicator: '#9BF0E1',
    },
    infoExchange: {
      badge: {
        labelBg: '#5b5b5b',
        valueBg: '#0274b5',
        border: '#424242',
        text: '#FFFFFF',
      },
    },
    prompt: {
      table: {
        domain: '#42526E',
        subdomain: '#465855',
        persona: '#50445D',
      },
      loader: {
        primary: '#C0D4CB',
        secondary: '#538184',
      },
      options: {
        background: '#3CD278',
        text: '#003F2D',
        button: '#999999',
        color: '#444444',
        header: '#435254',
        title: '#CCCCCC',
        border: '#333333',
        paper: '#292929',
        inputBackground: '#333333',
        bot: {
          background: '#7F8480',
          text: '#fff',
        },
        user: {
          background: '#565656',
          text: '#CCCCCC',
        },
      },
      chat: {
        background: '#333333',
      }
    },
    panel: {
      varient1: '#465855',
      varient2: '#42526E',
    },

    bgVariants: {
      variant1: '#464646',
      variant2: '#17E88F',
      variant3: '#333333',
      variant4: '#17E88F',
    },
    textVariants: {
      variant1: '#fff',
      variant2: '#fff',
      variant4: '#000000',
    },

    colors: {
      purple: '#A388BF',
      darkblue: '#42526E',
      greencyan: '#465855',
      sage: '#538184',
      blue: '#3E7CA6',
      gold: '#FF9742',
      green: '#003f2d',
      white: '#FFFFFF',
      richBlack: '#010c14',
      lightGreen: 'rgb(0, 63, 45, 8%)'
    },
  },

  defaultPageTheme: 'home',
  fontFamily: 'Calibre-R-Regular',
  pageTheme: {
    home: genPageTheme({ colors: ['#012A2D'], shape: shapes.wave }),
    darkGrey: ['#171717', '#383838'],
    marineBlue: ['#006D8F', '#0049A1'],
    veryBlue: ['#0027AF', '#270094'],
    rubyRed: ['#98002B', '#8D1134'],
    toastyOrange: ['#BE2200', '#A41D00'],
    purpleSky: ['#8912CA', '#3E00EA'],
    eveningSea: ['#00FFF2', '#035355'],
    teal: ['#005B4B'],
    pinkSea: ['#C8077A', '#C2297D'],
    greens: ['#4BB8A5', '#187656'],
  },
});

export default darkCbreTheme;
