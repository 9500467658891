import {
  BackstageIdentityResponse,
  appThemeApiRef,
  configApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, ComponentType } from 'react';
import { useMountEffect } from '@react-hookz/web';
import { useStyles } from './styles';
import {
  SignInPageProps,
  ApiHolder,
  ApiRef,
  ProfileInfoApi,
  BackstageIdentityApi,
  SessionApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import {
  Content,
  Page,
  Progress,
  SignInProviderConfig,
  UserIdentity,
} from '@backstage/core-components';
import { Box, Theme, useMediaQuery, useTheme } from '@material-ui/core';

import LogoFull from '../../Root/logo/LogoFull';
import { THEME_IDS } from '../../../App';

/**
 * Invoked when the sign-in process has failed.
 */
export type onSignInFailure = () => void;
/**
 * Invoked when the sign-in process has started.
 */
export type onSignInStarted = () => void;

export type ProviderComponent = ComponentType<
  SignInPageProps & {
    config: SignInProviderConfig;
    onSignInStarted(): void;
    onSignInFailure(): void;
  }
>;

export type ProviderLoader = (
  apis: ApiHolder,
  apiRef: ApiRef<ProfileInfoApi & BackstageIdentityApi & SessionApi>,
) => Promise<IdentityApi | undefined>;

export type SignInProvider = {
  Component: ProviderComponent;
  loader: ProviderLoader;
};

type SingleSignInPageProps = SignInPageProps & {
  provider: SignInProviderConfig;
  auto?: boolean;
};

export type Props = SingleSignInPageProps;

export const SingleSignInPage = ({
  provider,
  auto,
  onSignInSuccess,
}: SingleSignInPageProps) => {
  const classes = useStyles();
  const authApi = useApi(provider.apiRef);
  const configApi = useApi(configApiRef);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const appThemeApi = useApi(appThemeApiRef);
  const activeThemeId = appThemeApi.getActiveThemeId();

  const [error, setError] = useState<Error>();

  // The SignIn component takes some time to decide whether the user is logged-in or not.
  // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
  // displayed for a split second when the user is already logged-in.
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);

  type LoginOpts = { checkExisting?: boolean; showPopup?: boolean };
  const login = async ({ checkExisting, showPopup }: LoginOpts) => {
    try {
      let identityResponse: BackstageIdentityResponse | undefined;
      if (checkExisting) {
        // Do an initial check if any logged-in session exists
        identityResponse = await authApi.getBackstageIdentity({
          optional: true,
        });
      }

      // If no session exists, show the sign-in page
      if (!identityResponse && (showPopup || auto)) {
        // Unless auto is set to true, this step should not happen.
        // When user intentionally clicks the Sign In button, autoShowPopup is set to true
        setShowLoginPage(true);
        identityResponse = await authApi.getBackstageIdentity({
          instantPopup: true,
        });
        if (!identityResponse) {
          throw new Error(
            `The ${provider.title} provider is not configured to support sign-in`,
          );
        }
      }

      if (!identityResponse) {
        setShowLoginPage(true);
        return;
      }

      const profile = await authApi.getProfile();
      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          authApi,
          profile,
        }),
      );
    } catch (err: any) {
      // User closed the sign-in modal
      setError(err);
      setShowLoginPage(true);
    }
  };

  useMountEffect(() => login({ checkExisting: true }));

  function className(textColor: string, textCap: string): string | undefined {
    throw new Error('Function not implemented.');
  }

  return showLoginPage ? (
    <Page themeId="home">
      <Content noPadding>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#efefef',
            backgroundImage:
              activeThemeId === THEME_IDS.dark
                ? `url('/images/dark-bg-login.jpg')`
                : `url('/images/light-bg-login.jpg')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Box
            sx={{
              maxHeight: '562px',
              height: isMobile ? '450px' : '562px',
              width: '938px',
              boxShadow: '0px 3px 8px 0px #00000033',
            }}
          >
            <Grid
              container
              classes={classes}
              spacing={0}
              style={{ height: '100%' }}
            >
              <Grid item md={6} hidden={isMobile}>
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    backgroundImage: `${theme.page.backgroundImage}`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: 'cover',
                    backgroundBlendMode: 'lighten',
                  }}
                >
                  <Box sx={{ px: 4, pt: 4 }}>
                    <LogoFull isSignInPage />
                  </Box>
                  <Box
                    sx={{
                      px: 4,
                      flex: 1,
                      display: 'flex',
                      my: 'auto',
                      alignItems: 'center',
                    }}
                    flex={1}
                  >
                    <Box>
                      <Typography variant="h2" className={classes.textColor}>
                        Developer
                      </Typography>
                      <Typography variant="h2" className={classes.textColor}>
                        Exchange
                      </Typography>
                      <Typography variant="h2" className={classes.textColor}>
                        Marketplace
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} hidden={!isMobile}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '245px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                      flexDirection: 'column',
                      backgroundImage: `${theme.page.backgroundImage}`,
                      backgroundSize: 'cover',
                      backgroundBlendMode: 'lighten',
                      width: '300px',
                      textAlign: 'center',
                    }}
                  >
                    <Box sx={{ px: 4, pt: 2, pb: 1 }}>
                      <LogoFull isSignInPage />
                      <Typography variant="h5" className={classes.textColor}>
                        Developer Exchange Marketplace
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={6} sm={12} xs={12} hidden={isMobile}>
                <Box
                  sx={{
                    height: '100%',

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: theme.palette.background.paper,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: 'cover',
                    backgroundBlendMode: 'lighten',
                  }}
                >
                  <Box>
                    <Typography variant="h2">Signin using SSO</Typography>
                    <Button
                      style={{ marginTop: '16px' }}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        login({ showPopup: true });
                      }}
                    >
                      <Typography
                        className={`${classes.textColor}  ${classes.textCap}`}
                      >
                        Sign In
                      </Typography>
                    </Button>
                    {error && error.name !== 'PopupRejectedError' && (
                      <Typography variant="body1" color="error">
                        {error.message}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6} sm={12} xs={12} hidden={!isMobile}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '205px',
                  }}
                >
                  <Box
                    sx={{
                      height: '205px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      background: theme.palette.background.paper,
                      backgroundRepeat: 'repeat-y',
                      backgroundSize: 'cover',
                      backgroundBlendMode: 'lighten',
                      width: '300px',
                    }}
                  >
                    <Box>
                      <Typography variant="h5">Signin using SSO</Typography>
                      <Button
                        style={{ marginTop: '8px' }}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          login({ showPopup: true });
                        }}
                      >
                        <Typography
                          className={`${classes.textColor}  ${classes.textCap}`}
                        >
                          Sign In
                        </Typography>
                      </Button>
                      {error && error.name !== 'PopupRejectedError' && (
                        <Typography variant="body1" color="error">
                          {error.message}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Content>
    </Page>
  ) : (
    <Progress />
  );
};

export function SignInPage(props: Props) {
  return <SingleSignInPage {...props} />;
}
