import React from 'react';
import {
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Typography,
} from '@material-ui/core';

import CopyIcon from '../../../icons/CopyIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)', // for pages without content header
      marginBottom: '10px',
      '& nav': {
        display: 'flex',
      },
    },
    gridItemCardResponsive: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginBottom: '10px',
        width: '100%',
      },
    },
    editBtnStyle: {
      marginRight: '10px',
      marginTop: '10px',
    },
    editBtnResponsive: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    gridItemCardContent: {
      flex: 1,
    },
    spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    iconButton: {
      width: '96px',
      height: '36px',
      color: theme.palette.text.primary,
      borderRadius: '3px',
      fontSize: '16px',
      border: `1px solid ${theme.palette.text.primary}`,
      '& svg': {
        marginRight: '5px',
      },
    },
    rightAlignItem: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

export function UIUXContent() {
  const classes = useStyles();
  const { entity } = useEntity();
  const onClickLink = () => {
    navigator.clipboard.writeText(`npm install -save ${entity?.metadata?.componentname}`);
  }
  return (
    <>
      <Card className={classes.gridItemCard}>
        <CardContent className={classes.gridItemCardContent}>
          <Grid container className={classes.spaceBetween}>
            <Grid item xs={12} md={9}>
              <Typography variant='body1'>npm install -save {entity?.metadata?.componentname}</Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.rightAlignItem}>
              <IconButton onClick={onClickLink} className={classes.iconButton}>
                <CopyIcon />
                {' '}Copy
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}