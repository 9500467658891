import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../../styles';
import { IAvailableTool, IProvisionedTool } from '../../interfaces';
import ToolConfigIcon from '../../icons/ToolConfigIcon';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import AddIcon from '@material-ui/icons/Add';
import CONSTANTS from '../constants';

const { ENABLE_JIRAPLANNERBOT, ENABLE_ADOPLANNERBOT } = CONSTANTS.TOOLS;

const ToolCard = ({
  tool,
  provisionedTools,
  onAddToolClicked,
  onRemoveToolClicked,
  onHeadingClicked,
  buttonVisible,
}: {
  tool: IAvailableTool;
  provisionedTools: IProvisionedTool[] | undefined;
  onAddToolClicked: Function;
  onRemoveToolClicked: Function;
  onHeadingClicked: Function;
  buttonVisible: boolean;
}) => {
  const classes = useStyles();

  const toolName = tool.tool;
  const tagsToDisplay = 2;

  const provisionedTool = provisionedTools?.find(
    __provisionedTool => __provisionedTool.tool === toolName,
  );

  const metaData = tool?.metadata;
  const moreInfo = metaData?.moreinfo;

  return (
    <Card className={classes.toolCard}>
      <CardHeader
        classes={{
          action: classes.headerAction,
          avatar: classes.cardAvatar,
          title: classes.toolCardTitle,
        }}
        avatar={<div dangerouslySetInnerHTML={{ __html: metaData.image }} />}
        title={
          <Box
            className={classes.serviceName}
            component="div"
            onClick={() => onHeadingClicked()}
          >
            {metaData.name}
          </Box>
        }
        action={
          <>
            {/* {tool?.inputs?.length > 0 ? (
              <IconButton aria-label="settings">
                <ToolConfigIcon fill="" fontSize="medium" />
              </IconButton>
            ) : null} */}
            <Tooltip
              title="For more information, click here!"
              placement="top-end"
            >
              <IconButton
                aria-label="info"
                href={moreInfo.infolink}
                target="_blank"
              >
                <InfoOutlinedIcon color="primary" fontSize="medium" />
              </IconButton>
            </Tooltip>

            {tool.tool === ENABLE_JIRAPLANNERBOT ||
            tool.tool === ENABLE_ADOPLANNERBOT ? (
              <Button
                variant="contained"
                disabled={!buttonVisible}
                className={classes.addToolButton}
                {...(tool?.unprovision && {
                  onClick: () => onHeadingClicked(),
                })}
              >
                Enable/Disable
              </Button>
            ) : (
              <>
                {provisionedTool ? (
                  <>
                    {provisionedTool.statusmsg === 'Provisioned' ||
                    provisionedTool.statusmsg === 'Enabled' ? (
                      <Button
                        variant="contained"
                        disabled={!buttonVisible}
                        className={classes.addToolButton}
                        startIcon={<BlockIcon fontSize="small" />}
                        {...(tool?.unprovision && {
                          onClick: () => onRemoveToolClicked(),
                        })}
                      >
                        Disable
                      </Button>
                    ) : (
                      <Tooltip
                        title="Only application owners and admins can enable or disable this feature. Contact the tech owner to request enablement or admin access. Admins can toggle features on/off and accept terms."
                        placement="bottom-end"
                      >
                        <Button
                          variant="contained"
                          disabled={!buttonVisible}
                          className={classes.addToolButton}
                          startIcon={<AddIcon fontSize="small" />}
                          onClick={() => onAddToolClicked()}
                        >
                          Enable
                        </Button>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <>
                    <Tooltip
                      title="Only application owners and admins can enable or disable this feature. Contact the tech owner to request enablement or admin access. Admins can toggle features on/off and accept terms."
                      placement="bottom-end"
                    >
                      <Button
                        variant="contained"
                        disabled={!buttonVisible}
                        className={classes.addToolButton}
                        startIcon={<AddIcon fontSize="small" />}
                        onClick={() => onAddToolClicked()}
                      >
                        Enable
                      </Button>
                    </Tooltip>
                  </>
                )}
              </>
            )}
          </>
        }
      />
      <CardContent className={classes.toolContentSection}>
        <Typography
          variant="body2"
          className={classes.toolCardDescription}
          dangerouslySetInnerHTML={{
            __html: metaData.description,
          }}
        />
      </CardContent>

      <CardActions className={classes.actionSection}>
        <Box display="flex">
          {metaData.tags?.slice(0, tagsToDisplay).map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              size="small"
              className={classes.chip}
            />
          ))}
          {tool.tags && tool.tags.length > tagsToDisplay ? (
            <Chip
              label={`+${tool.tags.length - tagsToDisplay}`}
              size="small"
              className={classes.chip}
            />
          ) : null}
        </Box>
        {tool.tool === ENABLE_JIRAPLANNERBOT ||
        tool.tool === ENABLE_ADOPLANNERBOT ? null : (
          <>
            {provisionedTool?.statusmsg?.toLocaleLowerCase() === 'enabled' && (
              <Box>
                <Button classes={{ label: classes.cardActionButton }} disabled>
                  <CheckCircleIcon fontSize="small" />
                  <span>{provisionedTool?.statusmsg}</span>
                </Button>
              </Box>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default ToolCard;
