const Constants = {
  entityType: {
    openapi: 'openapi',
    utility: 'utility',
    framework: 'framework',
    iac: 'iac',
    package: 'package',
    salesforce: 'salesforce',
    ai: 'ai',
    extension: 'extension',
    uiux: 'uiux',
  },
  devxStatus: {
    approved: 'approved',
    notApproved: 'not approved',
    underReview: 'under review',
    resubmit: 'resubmit',
  },
};

export default Constants;
