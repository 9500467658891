import React, { useEffect, useState } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { LocalStorage } from './localStorage';
import {
  // SidebarOpenStateProvider,
  SidebarPinStateProvider,
} from '@backstage/core-components';
// import { SidebarCloseStateProvider } from './providers/SidebarCloseStateProvider';

const SideNavPage = ({ children }: { children: any }) => {
  const [isPinned, setIsPinned] = useState(() =>
    LocalStorage.getSidebarPinState(),
  );

  useEffect(() => {
    LocalStorage.setSidebarPinState(isPinned);
  }, [isPinned]);

  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down(768), {
    noSsr: true,
  });

  const toggleSidebarPinState = () => setIsPinned(!isPinned);

  // const [isOpen, setIsOpen] = useState(false);

  // const setOpen = (opened: boolean) => setIsOpen(opened);

  // const closeSideNav = () => setIsPinned(false);

  return (
    <SidebarPinStateProvider
      value={{
        isPinned,
        toggleSidebarPinState,
        isMobile,
      }}
    >
      {/* <SidebarOpenStateProvider
        value={{
          isOpen,
          setOpen,
        }}
      > */}
      {/* <SidebarCloseStateProvider value={{ onClose: closeSideNav }}> */}
      {children}
      {/* </SidebarCloseStateProvider> */}
      {/* </SidebarOpenStateProvider> */}
    </SidebarPinStateProvider>
  );
};

export default SideNavPage;
