import { Theme } from '@material-ui/core';

export const commonStyles = (theme: Theme) => {
  return {
    /* Common styles */
    pagePadding: {
      padding: theme.spacing(3),
    },
    heigt100Percent: {
      height: '100%',
    },
    width100Percent: {
      width: '100%',
    },
    width50PercentMobile: {
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    gridAreaPageHeader: {
      gridArea: 'pageHeader',
    },
    flex: {
      display: 'flex',
    },
    flexNoWrap: {
      flexWrap: 'nowrap',
    },
    flexStart: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '14px',
    },
    flexSpacebetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexCenter: {
      display: 'flex',
      verticalAlign: 'middle',
      alignItems: 'center',
    },
    flexEnd: {
      display: 'flex',
      justifyContent: 'end',
    },
    flexGrow1: {
      flexGrow: 1,
    },
    centerAlign: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    itemHidden: {
      visibility: 'hidden',
    },
    itemVisible: {
      visibility: 'visible',
    },
    sentenceCaseText: {
      fontSize: `${theme.spacing(2)}px`,
      textTransform: 'none',
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
    },
    outlined: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
    },
    btnSpacing: {
      marginRight: theme.spacing(2),
    },
    backGroundColor: {
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      textTransform: 'none',
    },

    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 24px 24px ',
      alignItems: 'end',
    },
    // boldText: {
    //   fontStyle: 'normal',
    //   fontWeight: 'bold',

    //   //   color: theme.palette.text.secondary,
    // },

    padding0: {
      padding: 0,
    },
    padding1: {
      padding: theme.spacing(1),
    },
    padding1point5: {
      padding: theme.spacing(1.5),
    },
    padding2: {
      padding: theme.spacing(2),
    },
    paddingBottom2: {
      paddingBottom: theme.spacing(2),
    },
    padding2Point5: {
      padding: theme.spacing(2.5),
    },
    padding3: {
      padding: theme.spacing(3),
    },

    padding4: {
      padding: theme.spacing(4),
    },

    marginTopPoint5: {
      marginTop: theme.spacing(0.5),
    },

    marginTop2: {
      marginTop: theme.spacing(2),
    },
    marginRight2: {
      marginRight: theme.spacing(2),
    },

    gap1: {
      gap: theme.spacing(1),
    },
    gap2: {
      gap: theme.spacing(2),
    },

    secondaryText: {
      color: theme.palette.text.secondary,
    },
    tertiaryText: {
      color: theme.palette.text.tertiary,
    },

    displayNone: {
      display: 'none',
    },

    overflowAuto: {
      overflow: 'auto',
    },

    /* Common stylings from file diff utility tool */
    width50Percent: {
      width: '45%',
    },

    minWidthAuto: {
      minWidth: 'auto',
    },
    minWidth600: {
      minWidth: `${theme.spacing(75)}px`,
    },
    fullWidth: {
      width: '100%',
    },
    fullHeight: {
      height: '100%',
    },
    noPadding: {
      padding: '0 !important',
    },
    noMargin: {
      margin: '0 !important',
    },
    marginBottomSmall: {
      marginBottom: theme.spacing(1),
    },
    marginLeftSmall: {
      marginLeft: theme.spacing(1),
    },

    marginBottom0: {
      marginBottom: 0,
    },
    marginBottom4: {
      marginBottom: theme.spacing(4),
    },

    bgWhiteAppha8: {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },

    borderRadius1: {
      borderRadius: theme.spacing(1),
    },
    borderRadiusTop1: {
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    },

    bottomBorderNone: {
      borderBottom: 'none',
    },

    /* Text Related */
    userCase: {
      textTransform: 'none',
    },

    /* Buttons */
    primaryButton8Percent: {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}08`,
      boxShadow: 'none',
      borderColor: '#0063cc',
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}10`,
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: `${theme.palette.primary.main}10`,
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },

    textButtonvariant1: {
      color: theme.palette.primary.main,
      boxShadow: 'none',
    },

    buttonvariant4: {
      color: theme.palette.textVariants.variant4,
      backgroundColor: `${theme.palette.bgVariants.variant4}`,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: `${theme.palette.bgVariants.variant4}ab`,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: `${theme.palette.bgVariants.variant4}ab`,
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },

    /* Font related */
    fontBold: {
      fontWeight: 'bold',
    },

    /* Colors */

    colorWhite: {
      color: theme.palette.common.white,
      fill: theme.palette.common.white,
    },

    transition1second: {
      transition: 'transform 1s ease-out',
    },

    rotateMinus45: {
      transform: 'rotate(-45deg)',
    },

    rotateZero: {
      transform: 'rotate(0deg)',
    },

    height500: {
      height: '500px',
    },
    height300: {
      height: '300px',
    },

    collapsed: {
      height: 0,
    },

    /* End of common stylings */
  } as const;
};
